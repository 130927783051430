@use '../../styles/shared/include' as *;
.search__sort {
	background-color: $deepBlue;
	position: sticky;
	top: calc(rem(-80) + var(--announcement-banner-height));
	z-index: 3;
	@media #{$md} {
		top:calc(rem(70) + var(--announcement-banner-height));
	}
	@media #{$xl} {
		top:calc(rem(78) + var(--announcement-banner-height));
	}
	:global {
		.banner__form {
			margin-bottom: rem(20);
			max-width: rem(660);
			padding-top: rem(18.4);
			@media #{$lg} {
				margin-bottom: rem(24);
			}
			form {
				label {
					color: $white;
					font-size: rem(16);
					font-weight: 500;
					line-height: 125%;
					display: block;
					margin-bottom: rem(4);
				}
				.form__group {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					gap: rem(16);
					.form__input {
						width: 100%;
						@media #{$md} {
							width: calc(100% - 130px);
							position: relative;
						}
						input {
							min-height: rem(44);
							padding: rem(12) rem(16);
							background-color: $white;
							border: none;
							outline: none;
							box-shadow: none;
							border-radius: rem(3);
							width: 100%;
							// @media #{$md}{
							//   width: calc(100% - 130px);
							// }
							&:focus {
								outline: auto;
								& + .close__x {
									opacity: 1;
									visibility: visible;
									pointer-events: all;
								}
							}
						}
						.close__x {
							position: absolute;
							top: 50%;
							right: rem(10);
							transform: translateY(-50%);
							background-color: transparent;
							cursor: pointer;
							pointer-events: none;
							opacity: 0;
							visibility: hidden;
						}
					}
					.form__button {
						a {
							padding: rem(12) rem(24);
							font-size: rem(16);
							// @media #{$md-m}{
							// }
						}
					}
				}
			}
		}
		.banner__scroll-to-sec {
			padding-bottom: rem(20);
			ul {
				display: flex;
				align-items: center;
				flex-wrap: nowrap;
				align-items: center;
				overflow-x: auto;
				gap: rem(5.1);
				padding-bottom: rem(4);
				&::-webkit-scrollbar {
					height: 2px;
				}
				li {
					cursor: pointer;
					strong {
						padding: rem(10);
						border: rem(1) solid transparent;
						border-radius: rem(4);
						display: block;
						font-size: rem(16);
						color: $white;
						line-height: normal;
						font-weight: 600;
						font-family: $secondary-inter;
						@include transition();
						@media #{$lg} {
							padding: rem(14) rem(15);
						}
						&.active,
						&:hover {
							color: $brightYellow;
							border-color: $brightYellow;
						}
					}
				}
			}
		}
	}
}
