@use '../../styles/shared/include' as *;

.search__result {
	position: relative;
	:global {
		.search__result-listing {
			padding: rem(65) 0;
			.search__result-byletter {
				margin-top: rem(60);
				@media #{$md} {
					margin-top: rem(100);
				}
				@media #{$lg} {
					margin-top: rem(120);
				}
				&:first-child {
					margin-top: 0;
				}
			}
			.container--max {
				position: relative;
				padding: 0;
				.filter__tag {
					position: sticky;
					left: 0;
					top: calc(rem(166) + var(--announcement-banner-height));
					padding: rem(10);
					padding-left: rem(15);
					background-color: $brightYellow;
					color: $deepBlue;
					font-size: rem(18);
					line-height: 100%;
					font-weight: 500;
					max-width: rem(45);
					z-index: 2;
					margin-bottom: rem(10);
					display: flex;
					justify-content: flex-end;
					@media #{$md} {
						top: calc(rem(257) + var(--announcement-banner-height));
						margin-bottom: rem(10);
					}
					@media #{$lg} {
						font-size: rem(24);
						top: calc(rem(271) + var(--announcement-banner-height));
						margin-top: rem(-10);
					}
					@media #{$xl} {
						top: calc(rem(281) + var(--announcement-banner-height));
						padding: rem(15) rem(20);
						max-width: rem(83);
						padding-left: rem(43);
					}
				}
			}
			.search__result-wrapper {
				display: grid;
				grid-template-columns: repeat(1, 1fr);
				grid-gap: rem(30);
				@media #{$md} {
					grid-template-columns: repeat(2, 1fr);
				}
				@media #{$lg} {
					grid-gap: rem(50);
					grid-template-columns: repeat(3, 1fr);
				}
				@media #{$xl} {
					grid-gap: rem(80);
					margin-top: rem(-44);
				}
				@media #{$xxl} {
					grid-gap: rem(120);
				}
			}
			.no__search__data {
				width: calc(100% - 24px);
				color: $deepBlue;
				font-size: rem(16);
				line-height: 120%;
				font-weight: 300;
				margin-top: rem(-40);
				padding-left: rem(40);
				min-height: rem(120);
				@media #{$md} {
					font-weight: 700;
					padding-left: rem(30);
					font-size: rem(24);
					letter-spacing: rem(0.4);
					margin-top: rem(-44);
				}
				@media #{$xl} {
					font-weight: 700;
					padding-left: rem(0);
					font-size: rem(24);
					letter-spacing: rem(0.4);
					margin-top: rem(-52);
				}
			}
		}
	}
}
